<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="card-padding">
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                class="table"
                :page.sync="page"
                hide-default-footer
                @page-count="pageCount = $event"
                :items-per-page="itemsPerPage"
                mobile-breakpoint="0"
              >
                <template v-slot:top>
                  <v-toolbar flat height="80">
                    <v-row>
                      <v-col cols="12" md="5">
                        <v-text-field
                          hide-details
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                          "
                          dense
                          flat
                          filled
                          solo
                          height="43"
                          v-model="search"
                          placeholder="Search"
                        >
                          <template slot="prepend-inner">
                            <v-icon color="#adb5bd" size=".875rem"
                              >fas fa-search</v-icon
                            >
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="newBlendCode"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      dark
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-success
                        py-3
                        px-6
                        mb-5
                        mt-5
                      "
                      >New
                    </v-btn>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.active`]="{ item }">
                  <span v-if="item.active"> Active </span>
                  <span v-else> Inactive </span>
                </template>
                <template v-slot:[`item.validFrom`]="{ item }"
                  >{{ formatdate(item.validFrom) }}
                </template>
                <template v-slot:[`item.validTo`]="{ item }">
                  {{ formatdate(item.validTo) }}</template
                >
                <template v-slot:[`item.actions`]="{ item }">
                  <!-- <span> Example test {{ item.district }}</span> -->
                  <v-btn
                    @click="editItem(item)"
                    icon
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class=""
                    color="green"
                    >Edit
                  </v-btn>

                  <v-btn
                    @click="deleteItem(item)"
                    icon
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="ml-5"
                    color="#FF0000"
                  >
                    delete
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <v-row>
                <v-col cols="6" lg="3" class="d-flex align-center">
                  <span class="text-body me-3 text-sm">Items per page:</span>
                  <v-text-field
                    hide-details
                    type="number"
                    outlined
                    min="-1"
                    max="15"
                    background-color="rgba(255,255,255,.9)"
                    color="rgba(0,0,0,.6)"
                    light
                    :value="itemsPerPage"
                    @input="itemsPerPage = parseInt($event, 10)"
                    placeholder="Items per page"
                    class="
                      font-size-input
                      placeholder-lighter
                      text-color-light
                      input-alternative input-focused-alternative input-icon
                    "
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="ml-auto d-flex justify-end">
                  <v-pagination
                    prev-icon="fa fa-angle-left"
                    next-icon="fa fa-angle-right"
                    class="pagination"
                    color="#38bd34"
                    v-model="page"
                    :length="pageCount"
                    circle
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Create Edit -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent>
        <v-form ref="frm">
          <v-card>
            <v-card-title>
              <span class="text-h5"
                ><h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Blend Code
                </h5></span
              >
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>Blend
                    Type</label
                  >
                  <v-select
                    v-model="editedItem.blendTypeId"
                    :items="blendType"
                    item-text="name"
                    item-value="id"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    outlined
                    height="46"
                    :rules="[(v) => !!v || 'Blend Type is required']"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>Blend
                    Category</label
                  >
                  <v-select
                    v-model.number="editedItem.blendCategoryId"
                    :items="belendCategory"
                    item-text="name"
                    item-value="id"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    :rules="[(v) => !!v || 'Blend Category is required']"
                    outlined
                    height="46"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span
                    >Name</label
                  >
                  <v-text-field
                    v-model="editedItem.name"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    persistent-hint
                    :rules="[(v) => !!v || 'Name is required']"
                  ></v-text-field>
                </v-col> </v-row
              ><v-row>
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>Min
                    Price</label
                  >
                  <v-text-field
                    v-model.number="editedItem.minAvgPrice"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    :rules="[
                      (v) => !!v || 'Min Price is required',
                      (v) => v > 0 || 'Min Price Should more than 0',
                    ]"
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    placeholder="Min Price"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>Max
                    Price</label
                  >
                  <v-text-field
                    v-model.number="editedItem.maxAvgPrice"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    :rules="[
                      (v) => !!v || 'Max Price is required',
                      (v) => v > 0 || 'Max Price Should more than 0',
                    ]"
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    placeholder="Max Price"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>Valid
                    from</label
                  >
                  <v-menu
                    ref="mnu_date"
                    v-model="mnu_date"
                    :close-on-content-click="false"
                    :return-value.sync="mnu_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.validFrom"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                          mt-5
                        "
                        dense
                        flat
                        filled
                        solo
                        height="46"
                        placeholder="Date"
                        persistent-hint
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="green lighten-1"
                      header-color="primary"
                      v-model="editedItem.validFrom"
                      no-title
                      scrollable
                      :min="today"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="mnu_date = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.mnu_date.save(editedItem.validFrom)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>Valid
                    To</label
                  >

                  <v-menu
                    ref="mnu_to_date"
                    v-model="mnu_to_date"
                    :close-on-content-click="false"
                    :return-value.sync="mnu_to_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.validTo"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details="auto"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                          mt-5
                        "
                        dense
                        flat
                        filled
                        solo
                        height="46"
                        placeholder="Date"
                        persistent-hint
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="green lighten-1"
                      header-color="primary"
                      v-model="editedItem.validTo"
                      no-title
                      scrollable
                      :min="editedItem.validFrom"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="mnu_to_date = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.mnu_to_date.save(editedItem.validTo)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>Leaf
                    Size</label
                  >
                  <v-select
                    v-model.number="editedItem.avgLeafSize"
                    :items="leaf_size"
                    item-text="value"
                    item-value="value"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    :rules="[(v) => !!v || 'Leaf Size is required']"
                    outlined
                    single-line
                    height="46"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span>Leaf
                    Appearance</label
                  >
                  <v-select
                    v-model.number="editedItem.avgAppearence"
                    :items="leaf_appearance"
                    item-text="value"
                    item-value="value"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    :rules="[(v) => !!v || 'Leaf Appearnce is required']"
                    outlined
                    single-line
                    height="46"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span
                    >Body</label
                  >
                  <v-select
                    v-model.number="editedItem.avgBody"
                    :items="body"
                    item-text="value"
                    item-value="value"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    outlined
                    single-line
                    height="46"
                    :rules="[(v) => !!v || 'Body is required']"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span
                    >Taste</label
                  >
                  <v-select
                    v-model.number="editedItem.avgTaste"
                    :items="taste"
                    item-text="value"
                    item-value="value"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    outlined
                    single-line
                    height="46"
                    :rules="[(v) => !!v || 'Taste is required']"
                  >
                  </v-select> </v-col
                ><v-col cols="12" md="2">
                  <label class="text-md font-weight-bolder ms-1 mandatory"
                    ><span class="red--text"><strong>* </strong></span
                    >Color</label
                  >
                  <v-select
                    v-model.number="editedItem.avgColor"
                    :items="color"
                    item-text="value"
                    item-value="value"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    :rules="[(v) => !!v || 'Color is required']"
                    outlined
                    single-line
                    height="46"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col class="12" offset-md="10"
                  ><v-btn
                    @click="clear"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-secondary
                      bg-light
                      py-3
                      px-6
                      mr-2
                    "
                    >Clear </v-btn
                  ><v-btn
                    @click="saveData"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                    "
                    >{{ actionType }}
                  </v-btn></v-col
                >
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>

    <!-- Delete Dialogue -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDelete"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
            >Cancel</v-btn
          >

          <v-btn
            @click="deleteItemConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            "
            >Ok</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "../api";
import blend from "../../../../api/blend-api";
import blendCategoryApi from "../../category/api";
import blendTypeApi from "../../type/api";
import formatDate from "../../../../global-data/dateFormatter";
export default {
  data() {
    return {
      overlay: false,
      headers: [],
      items: [],
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      //
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      editedIndex: -1,
      editedItem: {
        name: "",
        code: "",
        avgLeafSize: 0,
        avgAppearence: 0,
        avgBody: 0,
        avgColor: 0,
        avgTaste: 0,
        itemName: "",
        minAvgPrice: 0,
        maxAvgPrice: 0,
        blendTypeId: 0,
        blendCategoryId: 0,
        blendTypeName: "",
        blendCategoryName: "",
        id: 0,
        validFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        validTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      defaultItem: {
        name: "",
        code: "",
        avgLeafSize: 0,
        avgAppearence: 0,
        avgBody: 0,
        avgColor: 0,
        avgTaste: 0,
        itemName: "",
        minAvgPrice: 0,
        maxAvgPrice: 0,
        blendTypeId: 0,
        blendCategoryId: 0,
        blendTypeName: "",
        blendCategoryName: "",
        id: 0,
        validFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        validTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Code",
          align: "left",
          sortable: false,
          value: "code",
        },
        {
          text: "Size",
          align: "left",
          sortable: false,
          value: "avgLeafSize",
        },
        {
          text: "Appearance",
          align: "left",
          sortable: false,
          value: "avgAppearence",
        },
        {
          text: "Body",
          align: "left",
          sortable: false,
          value: "avgBody",
        },
        {
          text: "Color",
          align: "left",
          sortable: false,
          value: "avgColor",
        },
        {
          text: "Taste",
          align: "left",
          sortable: false,
          value: "avgTaste",
        },
        {
          text: "Item Name",
          align: "left",
          sortable: false,
          value: "itemName",
        },
        {
          text: "Min Price",
          align: "left",
          sortable: false,
          value: "minAvgPrice",
        },
        {
          text: "Max Price",
          align: "left",
          sortable: false,
          value: "maxAvgPrice",
        },
        {
          text: "Valid From",
          align: "left",
          sortable: false,
          value: "validFrom",
        },
        {
          text: "Valid To",
          align: "left",
          sortable: false,
          value: "validTo",
        },
        {
          text: "Type ",
          align: "left",
          sortable: false,
          value: "blendTypeName",
        },
        {
          text: "Category",
          align: "left",
          sortable: false,
          value: "blendCategoryName",
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          value: "active",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
      dialogDelete: false,
      //
      dialogBatchNo: false,
      //
      dialog: false,
      //
      overlay: false,
      //
      body: [],
      leaf_size: [],
      color: [],
      taste: [],
      leaf_appearance: [],
      belendCategory: [],
      blendType: [],
      //
      actionType: "Save",
      mnu_date: false,

      mnu_to_date: false,
    };
  },
  methods: {
    async initialize() {
      this.items = await api.getCode();
      this.belendCategory = await blendCategoryApi.getBlendCategory();
      this.blendType = await blendTypeApi.get();
      // ## Geting Blend factors
      let result = await blend.getBlendfactor();
      this.body = result.body;
      this.leaf_size = result.leaf_size;
      this.color = result.color;
      this.taste = result.taste;
      this.leaf_appearance = result.leaf_appearance;
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.actionType = "Update";
      this.editedItem = Object.assign({}, item);

      this.editedItem.validFrom = this.formatdate(item.validFrom);
      this.editedItem.validTo = this.formatdate(item.validTo);

      this.dialog = true;
    },
    async deleteItem(item) {
      this.dialogDelete = true;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.deleteCode(this.editedItem.id);
        this.items.splice(this.editedIndex, 1);

        this.showSuccessAlert(`Blend Code Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      }
    },
    newBlendCode() {
      this.dialog = true;
    },
    validate() {
      return this.$refs.frm.validate();
    },
    async saveData() {
      this.overlay = true;
      try {
        if (this.validate()) {
          if (this.editedIndex == -1) {
            await api.saveData(this.editedItem);
            this.showSuccessAlert("Blend Code Saved");
          } else {
            await api.updateData(this.editedItem.id, this.editedItem);
            this.showSuccessAlert("Blend Code Updated");
          }
          this.initialize();
          this.clear();
        }
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.overlay = false;
        this.dialog = false;
      }
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 1000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 2500,
        icon: "error",
        showConfirmButton: false,
      });
    },
    async clear() {
      this.actionType = "Save";
      this.editedItem = -1;
      this.editedItem = this.defaultItem;
      this.dialog = false;
      await this.$refs.frm.reset();

      this.editedItem.validFrom = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);

      this.editedItem.validTo = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    },
    formatdate(date) {
      return formatDate.formatDate(date);
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>

<style></style>
