import axios from "axios";
import { blendModuleAPI } from "../../../config";
let module = "/v1/BlendCode";

export default {
  async getCode() {
    return axios
      .get(`${blendModuleAPI}${module}`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveData(data) {
    return axios
      .post(`${blendModuleAPI}${module}`, data)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async updateData(id, data) {
    return axios
      .put(`${blendModuleAPI}${module}/` + id, data)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async deleteCode(id) {
    return axios
      .delete(`${blendModuleAPI}${module}/` + id)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
